import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Col, Row } from "react-bootstrap";
import { Field, Form, Formik, FormikProps } from "formik";
import { get, isEmpty } from "lodash";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { request } from "../../../../utils/Request";
import HasAccess from "../../../../utils/HasAccess";
import { InputField } from "../../../../components/Shared/InputField";
import { InputTextAreaField } from "../../../../components/Shared/InputTextAreaField";
import { InputSingleSelectField } from "../../../../components/Shared/InputSingleSelectField";

interface IFormProps {
    t?: any | undefined;
    selected?: any;
    toastify?: any;
    onClose?: any;
    onStore: any;
    onUpdate: any;
    allowAdd: boolean;
    allowEdit: boolean;
    classForAddButton?: string
    brandId?: string
    classForUpdateButton?: string
    labelForAddButton?: string
    labelForUpdateButton?: string
    fetchDropdowns?: boolean
    dropdowns?: any;
}

type TInputForm = {
    id: any,
    title: null | string,
    version_code: null | string,
    brand_id: null | undefined | string,
    description: null | string,
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        title: yup.string().trim(t('the field is invalid.')).required(t('the field is required.', { field: t('title') })).nullable(),
        version_code: yup.string().trim(t('the field is invalid.')).nullable(), // required(t('the field is required.', { field: t('version code') }))
        brand_id: yup.string().required(t('the field is required.', { field: t('brand') })).nullable(),
        description: yup.string().nullable(),
    });
};

const DEFAULT_FORM_STATE = {
    id: null,
    title: null,
    version_code: null,
    brand_id: null,
    description: null,
};

const DEFAULT_DROPDOWNS = {
    brands: []
};

// @ts-ignore
function QuickBrandModelModalForm(props: IFormProps) {
    const { t, toastify } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [modal, setModal] = useState(false);
    const [isLoaded, setLoading] = useState<number>(1);
    const [entity, setEntity] = useState<any>(null);
    const [dropdowns, setDropdowns] = useState<any>(DEFAULT_DROPDOWNS);

    const toggle = (value: boolean = false, operation = 'store') => {
        setLoading(-1);
        if ( !value ) {
            if ( !isEmpty(props.onClose) ) {
                props.onClose();
                setInitFormState(initFormState);
                return;
            }
        }

        if ( props.fetchDropdowns ) {
            request({
                url: '/suppliers/models/create',
                params: {
                    type: 'list'
                }
            })
            .then((response: any) => {
                const { data } = response.data;
                setDropdowns({ brands: data.brands ?
                    data.brands.map((item: any) => ( { ...item, key: item.id } ))
                : [] });
            })
            .catch(() => setDropdowns(DEFAULT_DROPDOWNS))
            .finally(() => {
                if ( operation === 'update' ) {
                    setModal(value);
                    onEdit();
                } else {
                    setModal(value);
                    setLoading(1);
                    const formDate = {
                        ...DEFAULT_FORM_STATE,
                        brand_id: ( !isEmpty(props.brandId) ? props.brandId : null )
                    };
                    setInitFormState(formDate);
                }
            });
            return;
        } else {
            setModal(value);
            setLoading(1);
            setDropdowns(props.dropdowns);
            setTimeout(() => {
                const formDate = {
                    ...initFormState,
                    brand_id: ( !isEmpty(props.brandId) ? props.brandId : null )
                };
                setInitFormState(formDate);
            }, 500);
        }
    };

    const onSubmit = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        try {
            const response = await request({
                method: ( !payloads.id ? 'POST' : 'PATCH' ),
                url: ( !payloads.id ? `/suppliers/models` : `/suppliers/models/${ payloads.id }` ),
                data: { ...payloads, isQuick: true },
            });

            if ( payloads.id ) {
                if ( props.onUpdate ) {
                    props.onUpdate(response.data.data);
                }

                toastify(t("record updated", { item: t('brand') }), "success");
            } else {
                if ( props.onStore ) {
                    props.onStore(response.data.data);
                }

                toastify(t("record added", { item: t('brand') }), "info");
            }

            resetForm();
            toggle(false);
        } catch (e: any) {
            if ( e.status === 422 ) {
                setErrors(e.data.errors);
            } else {
                toastify(t("server error"), "error");
            }
        }
    };

    const onEdit = async () => {
        if ( !isEmpty(props.selected) ) {
            setLoading(-1);
            try {
                const response = await request({
                    url: `/suppliers/models/${ props.selected }`
                });

                const { data } = response.data;
                setEntity(data);
                const formData: TInputForm = { ...data, brand_id: data.brand.id };
                setInitFormState(formData);
            } catch (error: any) {
                toastify(t("server error"), "error");
            } finally {
                setLoading(1);
            }
        }
    };

    useEffect(() => {
        setInitFormState(DEFAULT_FORM_STATE);
    }, []);

    return (
        <>
            <Formik
                innerRef={ formRef }
                enableReinitialize={ true }
                initialValues={ initFormState }
                onSubmit={ onSubmit }
                validationSchema={ validationSchema(t) }
            >
                { (formikProps: FormikProps<TInputForm>) => {
                    return (
                        <>
                            <span>
                                <HasAccess hasAnyAccess={ ['modelsstore'] }>
                                    <a className={ props.classForAddButton ?? "" }
                                       onClick={ () => toggle(true, 'store') }
                                    >{ props.labelForAddButton ?? t('add') }</a>
                                </HasAccess>
                                {
                                    ( props.selected && !isEmpty(props.selected) ) &&
                                    <HasAccess hasAnyAccess={ ['modelsupdate'] }>
                                        <a
                                          className={ props.classForUpdateButton ?? "" }
                                          onClick={ () => toggle(true, 'update') }>
                                            { props.labelForUpdateButton ?? t('update') }
                                        </a>
                                    </HasAccess>
                                }
                            </span>
                            <Dialog visible={ modal } onHide={ () => toggle(false) }
                                    header={<>
                                        { t('model') } { t('action panel') }
                                    </>}
                                    style={{ width: '50vw' }} breakpoints={{ '960px': '85vw', '641px': '100vw' }}>
                                <Form onSubmit={ formikProps.handleSubmit }>
                                    <Row>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputSingleSelectField }
                                                   name="brand_id"
                                                   disabled={ !isEmpty(props.brandId) || !isEmpty(get(entity, ['brand', 'id'])) }
                                                   options={ dropdowns?.brands }
                                                   label={ t('brand') }
                                                   placeholder={ t("pick") + ' ' + t("brand").toLowerCase() }
                                                   isRequired
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="title" label={ t("title") }
                                                   placeholder={ t("enter") + ' ' + t("title").toLowerCase() }
                                                   isRequired autoFocus />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputField }
                                                   name="version_code" label={ t("version code") }
                                                   placeholder={ t("enter") + ' ' + t("version code").toLowerCase() } />
                                        </Col>
                                        <Col sm={ 12 } md={ 12 }>
                                            <Field component={ InputTextAreaField } name="description"
                                                   label={ t("description") } type={ "textarea" } />
                                        </Col>
                                    </Row>
                                    <div className={ "text-end" }>
                                        { ( isLoaded < 0 ) && "Loading..." }
                                        {
                                            ( isLoaded > 0 ) && <>

                                              <Button type={ "button" } disabled={ formikProps.isSubmitting }
                                                      tooltip={ t("to data save into database") }
                                                      size={ "small" } outlined
                                                      tooltipOptions={ { position: 'top' } }
                                                      onClick={ formikProps.submitForm }
                                              >
                                                  <i className="pi pi-save me-2" />
                                                  { t("let's save") }
                                              </Button>
                                              <Button type={ "button" } disabled={ formikProps.isSubmitting }
                                                      onClick={ () => formikProps.resetForm() }
                                                      tooltip={ t("to make all fields empty") } className={ "ms-2" }
                                                      size={ "small" } severity={ "warning" } outlined
                                                      tooltipOptions={ { position: 'top' } }
                                              >
                                                  <i className="pi pi-refresh me-2" />
                                                  { t("reset") }
                                              </Button>
                                              <Button type={ "button" } disabled={ formikProps.isSubmitting }
                                                      tooltip={ t("operation cancel") } className={ "ms-2" }
                                                      size={ "small" } severity={ "danger" } outlined
                                                      tooltipOptions={ { position: 'top' } }
                                                      onClick={ () => toggle(false) }
                                              >
                                                  <i className="pi pi-arrow-left me-2" />
                                                  { t("cancel") }
                                              </Button>
                                            </>
                                        }
                                    </div>

                                </Form>
                            </Dialog>
                        </>
                    );
                } }
            </Formik>
        </>
    );
}

QuickBrandModelModalForm.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(QuickBrandModelModalForm);
