import React, { useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { FormikProps, Formik, Form, Field } from "formik";
import * as yup from "yup";
import { request } from "../../../../utils/Request";
import { Col, Row } from "react-bootstrap";
import { Sidebar } from "primereact/sidebar";
import { clone, find, findIndex, get, isEmpty, sumBy } from "lodash";
import { Button } from "primereact/button";
import { dateToUtcDate, formatDate, random, utcDateToLocalDate } from "../../../../utils/Helper";
import { InputSingleSelectField } from "../../../../components/Shared/InputSingleSelectField";
import InputDateTime from "../../../../components/Shared/InputDateTime";
import { InputField } from "../../../../components/Shared/InputField";
import HasAccess from "../../../../utils/HasAccess";
import { InputTextAreaField } from "../../../../components/Shared/InputTextAreaField";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Tooltip from "../../../../components/Common/Display/Tooltip";
import { confirmPopup } from "primereact/confirmpopup";

interface IFormProps {
    t?: any | undefined;
    onClose: any;
    visibility: any;
    dropdowns: any;
    toastify: any;
}

type TInputForm = {
    serial?: any,
    id?: any,
    client_id?: any,
    route_id?: any,
    payment_date?: any,
    payment_mode?: any,
    price_type?: any,
    status?: any,
    price?: any,
    token?: any
    group_token?: any
    invoice_number?: any
    is_added_from_load?: boolean
    comments?: any
};

const DEFAULT_DROPDOWNS = {
    paymentMethods: [],
    clients: [],
};

const validationSchema = (t: Function): any => {
    return yup.object().shape({
        payment_mode: yup.string().required(t('the field is required.', { field: t('payment mode') })).nullable(),
        client_id: yup.string().required(t('the field is required.', { field: t('client') })).nullable(),
        // route_id: yup.string().required(t('the field is required.', { field: t('client_id') })).nullable(),
        status: yup.string().required(t('the field is required.', { field: t('status') })).nullable(),
        payment_date: yup.date(t('the field value is not valid', { field: t("payment date") })).required(t('the field is required.', { field: t('payment date') })).nullable(),
        price: yup.number().min(0).required(t('the field is required.', { field: t('total') })).nullable(),
    });
};

const DEFAULT_FORM_STATE: TInputForm = {
    serial: null,
    id: null,
    payment_date: null,
    payment_mode: null,
    client_id:  null,
    route_id: null,
    group_token: null,
    price_type: null,
    invoice_number: null,
    is_added_from_load: false,
    status: 'paid',
    price: 0,
    comments: null,
    token: random(10),
};

function LoadPrePayment(props: IFormProps) {
    const { t, toastify } = props;
    const formRef: any = useRef();
    const [initFormState, setInitFormState] = useState<TInputForm>(DEFAULT_FORM_STATE);
    const [panelState, setPanelState] = useState<boolean>(false);
    const [dropdowns, setDropdowns] = useState<any>(DEFAULT_DROPDOWNS);
    const [payments, setPayments] = useState<any>([]);

    const onClose = () => {
        setPanelState(false);
        setDropdowns({
            paymentMethods: [],
            clients: [],
            routes: []
        });

        if ( props.onClose ) {
            props.onClose();
        }
    };

    const onSubmit = async () => {
        try {
            const response = await request({
                method: 'PUT',
                url: `/suppliers/loads/pre/payments/1`,
                data: {
                    payments: payments.map((item: any) => ({...item, payment_date: dateToUtcDate(item.payment_date)})),
                    load_id: props.visibility.id,
                    load_number: props.visibility.load_number,
                    load_type: props.visibility.type,
                }
            });

            toastify(t("record updated", { item: t('payment') }), "success");
            onClose();
        } catch (e: any) {
            if ( e.status === 422 ) {
                const ref: any = formRef.current;
                ref.setErrors(e.data.errors);
                if ( !isEmpty(e.data.errors.invalid_total) ) {
                    toastify(t("invalid payment total"), "error");
                }
            } else {
                toastify(t("server error"), "error");
            }
        }
    };

    const onSave = async (payloads: TInputForm, { setErrors, resetForm }: any) => {
        // client_id~~~route_id~~~group_token //Supplier\Domain\Loads\Actions\DropdownLoadAction
        let client = find(dropdowns.clients, {id: payloads.client_id});

        if ( payloads.price_type === "per_route" ) {
            const exp = payloads.client_id.split("~~~");

            payloads.client_id = exp[0];
            payloads.route_id = exp[1];
            payloads.group_token = exp[2];
            client = find(dropdowns.clients, {client_id: payloads.client_id, route_id: payloads.route_id, group_token: payloads.group_token});
        }

        const _payments: any = [...payments];
        let existing = find(payments, { token: payloads.token });

        const existingAmount = get(existing, ['price']) || 0;
        const total: number = (sumBy(_payments, (item: any) => Number(item.price)) - existingAmount) + Number(payloads.price);

        if ( total > Number(client.limit) ) {
            setErrors({
                'client_id': t('invalid total')
            });

            return;
        }

        if ( !isEmpty(existing) ) {
            const index = findIndex(_payments, { token: payloads.token });
            existing = { ...payloads };
            _payments[index] = existing;
        } else {
            _payments.push({
                ...payloads,
                serial: (payments.length + 1),
                token: random(10),
            });
        }

        setPayments(_payments);
        resetForm();

        setInitFormState({
            ...DEFAULT_FORM_STATE,
            price_type: props.visibility.price_type,
            payment_mode: "cash",
        });
    };

    const onEdit = (token: any) => {
        const payment: any = clone(find(payments, { token: token }));

        if ( payment ) {
            if ( payment.price_type === "per_route" ) {
                // client_id~~~route_id~~~group_token
                const client: string = `${payment.client_id}~~~${payment.route_id}~~~${payment.group_token}`;
                payment.client_id = client;
            }

            setInitFormState(payment);
        } else {
            toastify(t("no data"), "error");
        }
    };

    const onDelete = (id: string) => {
        const shallowPayments = payments.filter((item: any) => item.token !== id);
        setPayments(shallowPayments);
    };

    const getDropdowns = async () => {
        if ( isEmpty(props.visibility ) && isEmpty(props.visibility.id) ) {
            return false;
        }

        try {
            const response = await request({
                url: '/suppliers/loads/pre/payments/create',
                params: {
                    type: 'payments',
                    sub_type: {
                        load_type: props.visibility.type,
                        load_id: props.visibility.id,
                    },
                }
            });

            const { data } = response.data;

            setDropdowns({
                paymentMethods: data.paymentMethods,
                clients: data.clients,
            });

            await getList(data.id, (data.clients.length == 1 ? get(data, ['clients', '0', 'id']) : null));
        } catch (e) {
            setDropdowns(DEFAULT_DROPDOWNS);
        }
    };

    // default fetch all the entries for specific invoice
    const getList = async (id: string, client_id = null): Promise<void> => {
        try {
            const response = await request({
                url: '/suppliers/loads/pre/payments',
                params: {
                    invoice_id: id, // to get a specific invoice detail
                    filters: {
                        loadType: props.visibility.type,
                        loadNumbers: [props.visibility.load_number],
                    }
                }
            });

            const { data } = response.data;
            const collection: TInputForm[] = [];

            data.map((item: any, index: number) => {
                collection.push({
                    serial: (index + 1),
                    id: item.id,
                    token: item.id,
                    payment_mode: item.payment_mode,
                    payment_date: utcDateToLocalDate(item.payment_date, 'YYYY-MM-DD HH:mm:ss'),
                    client_id: get(item.client, ['id']),
                    route_id: get(item.route, ['id']),
                    price_type: item.price_type,
                    price: item.price,
                    status: item.status,
                    comments: item.comments,
                    group_token: item.group_token,
                    invoice_number: item.invoice_number,
                    is_added_from_load: item.is_added_from_load,
                });
            })

            setPayments(collection);
            setPanelState(true);

            setTimeout(() => {
                setInitFormState({
                    ...DEFAULT_FORM_STATE,
                    client_id: client_id,
                    price_type: props.visibility.price_type,
                    payment_mode: "cash",
                });
            }, 500);

        } catch (error: any) {
            // console.log(error)
            toastify(t("server error"), "error");
        }
    };

    useEffect(() => {
        if ( !isEmpty(props.visibility.id) ) {
            setInitFormState(DEFAULT_FORM_STATE);

            getDropdowns();
        }
    }, [props.visibility]);

    return (
        <>
            <div>
                <Sidebar
                    header={
                        <div className="bg-body-tertiary card-header">
                            <h5 className={ "card-title mt-0" }>{ t('make payment') } #{ props.visibility?.load_number }</h5>
                            <p>{ t("all prepayments will be applied to the invoice") }</p>
                        </div>
                    }
                    visible={ panelState } position="right" onHide={ onClose } blockScroll={ true }
                    style={ { width: '800px' } }
                >
                    <div style={ {
                        height: '3px',
                        background: 'linear-gradient(90deg, var(--primary-color) 0%, rgba(33, 150, 243, 0) 50%)'
                    } }></div>
                    <Formik
                        innerRef={ formRef }
                        enableReinitialize={ true }
                        initialValues={ initFormState }
                        onSubmit={ onSave }
                        validationSchema={ validationSchema(t) }
                    >
                        { (formProps: FormikProps<TInputForm>) => {

                            return (
                                <Form onSubmit={ formProps.handleSubmit }>
                                    <Row className={ "mt-4 mb-3" }>
                                        <Col sm={ 12 } md={ 8 }>
                                            <Field
                                                component={ InputSingleSelectField }
                                                name="client_id"
                                                options={ dropdowns.clients }
                                                label={ t('client') }
                                                placeholder={ t('pick') + ' ' + t('client') }
                                                isRequired
                                                autoFocus
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 4 }>
                                            <Field
                                                placeholder={ t("payment date") }
                                                component={ InputDateTime }
                                                name="payment_date"
                                                type={ "datetime" }
                                                label={ t("payment date") }
                                                // maxDate={ moment().tz(moment.tz.guess()).toDate() }
                                                isRequired
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 4 }>
                                            <Field
                                                component={ InputSingleSelectField }
                                                name="payment_mode"
                                                options={ dropdowns.paymentMethods }
                                                label={ t('payment mode') }
                                                placeholder={ t('pick') + ' ' + t('payment mode') }
                                                isRequired
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 4 }>
                                            <Field component={ InputField }
                                                   type={ "number" }
                                                   name="price"
                                                   label={ t("price") }
                                                   placeholder={ t("enter") + ' ' + t("price").toLowerCase() }
                                                   isRequired />
                                        </Col>
                                        <Col sm={ 12 } md={ 4 }>
                                            <Field
                                                component={ InputSingleSelectField }
                                                name="status"
                                                label={ t("status") }
                                                options={ [
                                                    { id: "paid", key: "paid", label: t('paid') },
                                                    { id: "open", key: "open", label: t("open") }
                                                ] }
                                                placeholder={ t('pick') + ' ' + t('status') }
                                                isRequired
                                            />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 }>
                                            <Field component={ InputTextAreaField }
                                                   name="comments"
                                                   label={ t("comment") }
                                                   placeholder={ t("enter") + ' ' + t("comment").toLowerCase() } />
                                        </Col>
                                        <Col sm={ 12 } md={ 6 } className={ "text-end" }>
                                            <Row className={"mt-5"}>
                                                <Col sm={ 12 } md={6}>
                                                    {
                                                        ( initFormState && initFormState.id ) ?
                                                            <Button type="submit" severity={ undefined } size={ "small" } outlined
                                                                    title={ t("save") } className={ "d-block w-full" }>
                                                                <i className="fas fa-plus" />
                                                            </Button>
                                                            :
                                                            <HasAccess hasAllAccess={ ['invoicepaymentsstore'] }>
                                                                <Button type="submit" severity={ undefined } size={ "small" } outlined
                                                                        title={ t("save") } className={ "d-block w-full" }>
                                                                    <i className="fas fa-plus" />
                                                                </Button>
                                                            </HasAccess>
                                                    }
                                                </Col>
                                                <Col sm={ 12 } md={6}>
                                                    <HasAccess hasAnyAccess={ ['invoicepaymentsstore','invoicepaymentsupdate'] }>
                                                        <Button type="button" severity={ "danger" } size={ "small" } outlined
                                                                className={ "d-block w-full" } tooltip={t("reset")}
                                                                tooltipOptions={ { position: "top" } }
                                                                onClick={ () => formProps.resetForm() }>
                                                            <i className="fas fa-redo-alt" />
                                                        </Button>
                                                    </HasAccess>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            );
                        } }
                    </Formik>
                    {
                        <>
                            <HasAccess hasAnyAccess={ ['invoicepaymentsview'] }>
                                <h5 className={"mt-0 mb-2 text-capitalize"}>{t('payments')}</h5>
                                <DataTable
                                    className={ "md:mb-7 sm:mb-7" }
                                    value={ payments } dataKey="token"
                                    reorderableColumns size={ "small" }
                                    dragSelection resizableColumns showGridlines
                                    scrollable scrollHeight="750px">
                                    <Column field="#" header={ '#' } sortable={false}
                                    body={(item: any) => item.serial }/>

                                    <Column field="client" header={ t('client') }
                                    body={(item: any) => <>{ get(find(props.dropdowns.clients, { id: item.client_id }), ['label']) }</>}/>

                                    <Column field="route" header={ t('route') }
                                    body={(item: any) => <>{ get(find(props.dropdowns.routes, { id: item.route_id }), ['label']) }</>}/>

                                    <Column field="payment_mode" header={ t('payment mode') }
                                    body={(item: any) => <>{
                                        !isEmpty(
                                            find(dropdowns.paymentMethods, { id: item.payment_mode })
                                        )
                                        ? get(find(dropdowns.paymentMethods, { id: item.payment_mode }), ['label'])
                                        : item.payment_mode
                                    }</>}/>

                                    <Column field="payment_date" header={ t('payment date') }
                                    body={(item: any) => <>{ formatDate(item.payment_date, 'DD-MM-YYYY hh:mm A') }</>}/>

                                    <Column field="status" header={ t('status') }
                                    body={(item: any) => <>{ item.status ? t(item.status) : "-" }</>}/>

                                    <Column field="price" header={ t('price') } />

                                    <Column field="action" header={ t('action') }
                                    body={(item: any) => <>
                                        {
                                            ( isEmpty(item.invoice_number) && item.is_added_from_load !== true)
                                            ?
                                            <>
                                                <HasAccess hasAnyAccess={ ['invoicepaymentsupdate'] }>
                                                    <Tooltip tooltip={ t('edit item') } position={ "top" }>
                                                        <a
                                                          onClick={ () => onEdit(item.token) }
                                                          className={ `cursor-pointer text-info me-1` }
                                                          target={ "_blank" }
                                                          rel="noopener noreferrer">
                                                            <i className="fas fa-edit" />
                                                        </a>
                                                    </Tooltip>
                                                </HasAccess>
                                                <HasAccess hasAnyAccess={ ['invoicepaymentsdestroy'] }>
                                                    <Tooltip tooltip={ t('delete item') } position={ "top" }>
                                                        <a
                                                          title={ t('delete item') }
                                                          className={ "cursor-pointer ms-1 text-red-500" }
                                                          onClick={ (event: any) => confirmPopup({
                                                              target: event.currentTarget,
                                                              message: t('do you want to delete this record?'),
                                                              icon: 'pi pi-info-circle',
                                                              // @ts-ignorer
                                                              defaultFocus: 'reject',
                                                              acceptClassName: 'p-button-danger',
                                                              accept: () => onDelete(item.token),
                                                              reject: () => {
                                                              },
                                                          }) }>
                                                            <i className="fas fa-trash-alt" />
                                                        </a>
                                                    </Tooltip>
                                                </HasAccess>
                                            </>
                                            : item.invoice_number
                                        }
                                    </> } />
                                </DataTable>
                            </HasAccess>
                        </>
                    }

                    <div className="filter-card-footer"
                         style={ { width: '785px' } }>
                        <Button type={ "button" }
                                onClick={ onSubmit }
                                tooltip={ t("to data save into database") }
                                size={ "small" } outlined
                                tooltipOptions={ { position: 'top' } }
                        >
                            <i className="pi pi-save me-2" />
                            { t("let's save") }
                        </Button>
                        <Button type={ "button" }
                                tooltip={ t("operation cancel") } className={ "ms-2" }
                                size={ "small" } severity={ "danger" } outlined
                                tooltipOptions={ { position: 'top' } }
                                onClick={ onClose }
                        >
                            <i className="pi pi-arrow-left me-2" />
                            { t("cancel") }
                        </Button>
                    </div>
                </Sidebar>
            </div>
        </>
    );
}

//     LoadPrePayment.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(LoadPrePayment);
